.short {
	display: grid;
	flex-direction: column;
	--border-color: rgb(255, 255, 255);
}

.short > .challenge {
	height: 100%;
	display: flex;
	flex-direction: column;

	border-bottom: 1px solid white;
	padding: 1rem 0;
}

.short > .challenge:nth-child(1) {
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}

.short > .facts {
	display: flex;
	border-bottom: 1px solid white;
	grid-template-columns: 1fr 1fr 1fr 2fr;
}

.short > .facts > .fact {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: top;
	padding: 1rem 0;
}

.short > .facts > .fact > span {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 1.2rem;
}

.short > .facts > .fact > span > p {
	font-size: 1rem;
}
.short > .facts > .fact > .icons {
	display: flex;
	gap: 5px;
}

.short > .facts > .fact:nth-child(1) {
	padding-right: 1rem;
}

.short > .facts > .fact:nth-child(2) {
	border-left: 1px solid var(--border-color);
	padding: 1rem;
}

.short > .facts > .fact:nth-child(3) {
	border-left: 1px solid var(--border-color);
	padding: 1rem;
}

.short > .facts > .fact:nth-child(4) {
	border-left: 1px solid var(--border-color);
	padding: 1rem;
}

.short > .challenge > h4 {
	padding-bottom: 0.5rem;
}

.short > .facts > .fact > h4 {
	padding-bottom: 0.5rem;
}

@media (max-width: 550px) {
	.short > .facts {
		display: flex;
		flex-wrap: wrap;
		border-bottom: none;
	}

	.short > .facts > .fact:nth-child(1) {
		border-bottom: 1px solid var(--border-color);
		border-left: none;
		padding-left: 0;
	}

	.short > .facts > .fact:nth-child(2) {
		border-bottom: 1px solid var(--border-color);
		border-left: none;
		padding-left: 0;
	}

	.short > .facts > .fact:nth-child(3) {
		border-bottom: 1px solid var(--border-color);
		border-left: none;
		padding-left: 0;
	}

	.short > .facts > .fact:nth-child(4) {
		border-bottom: 1px solid var(--border-color);
		border-left: none;
		padding-left: 0;
	}
}
