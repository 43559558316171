.tiles {
	display: grid;

	margin: 0px auto;
	padding: 0px 1rem 4rem;
	grid-gap: 4vmin;

	grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
	max-width: 1200px;
	--border: rgb(3, 169, 244);

	--g1: rgb(98, 0, 234);
	--g2: rgb(236, 64, 122);
	--g3: rgb(253, 216, 53);
}

.tile {
	border: 0.5vmin solid var(--border);
	cursor: pointer;
	position: relative;
}

.tile:hover:before {
	background-position: 100% 100%;
	transform: scale(1.05, 1.05);
}

.tile:hover > .tile-content {
	background-position: -10% 0%;
}

.tile:before {
	background: linear-gradient(
		130deg,
		transparent 0% 33%,
		var(--g1) 66%,
		var(--g2) 83.5%,
		var(--g3) 100%
	);
	background-position: 0% 0%;
	background-size: 300% 300%;
	content: "";
	height: 100%;
	left: 0px;
	pointer-events: none;
	position: absolute;
	top: 0px;
	transition: background-position 350ms ease, transform 350ms ease;
	width: 100%;
	z-index: 1;
}

.tile-content {
	background-image: radial-gradient(
		rgba(255, 255, 255, 0.2) 5%,
		transparent 8%
	);
	background-position: 0% 0%;
	background-size: 2rem 2rem;
	height: calc(100%);
	padding: 3vmin;
	position: relative;
	transition: background-position 350ms ease;
	width: calc(100%);
	z-index: 2;
}

.tile-title,
.tile-subtitle {
	margin: 0px;
}

.tile-subtitle {
	margin-top: 2vmin;
}

.image-wrapper img {
	display: block;
	width: 100%;
}
.image-wrapper div {
	position: relative;
	display: inline-block;
	/* if you need ie6/7 support */
	*display: inline;
	zoom: 1;
	margin: 1rem 0;
}

/* -- Responsiveness -- */

@media screen and (max-width: 992px) {
	.tile-title {
		font-size: 2rem;
		line-height: 120%;
	}

	.tile-subtitle {
		font-size: 1.2rem;
		line-height: 150%;
	}
}

@media screen and (max-width: 400px) {
	.tiles {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
}
