.fun {
	padding-bottom: 2rem;
	width: 100%;
}

.fun .box {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.fun .light span {
	font-size: 4rem;
	font-weight: 400;
}

.fun .big span {
	font-size: 5rem;
	font-weight: 700;
}

.fun .opacity span {
	font-size: 3rem;
	font-weight: 200;
	opacity: 0.5;
}

.fun .buttons {
	display: flex;
}

.fun span {
	display: inline-block;
	padding: 0;
	margin: 0 0px 0 0px;
	user-select: none !important;
	transition: 0.3s;
	font-size: 4rem;
	font-weight: 700;
}

.fun span.space {
	padding: 0 5px !important;
	margin: 0 2px;
}

.fun span.letter:nth-child(even):hover {
	scale: 1.1;
	transform: rotate(10deg);
}

.fun span.letter:nth-child(odd):hover {
	scale: 1.1;
	transform: rotate(-10deg);
}

@media screen and (max-width: 780px) {
	.fun .light span {
		font-size: 3rem;
		font-weight: 400;
	}

	.fun .big span {
		font-size: 3rem;
		font-weight: 700;
	}

	.fun .opacity span {
		font-size: 1.8rem;
		font-weight: 200;
		opacity: 0.5;
	}

	.fun .text {
		font-size: 1.1rem !important;
	}
}
