.hero {
	width: 100%;
	padding: 6rem 0 5rem;
	position: relative;
}

.hero::before {
	content: "";

	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	opacity: 0.5;
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.hero .content {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
	padding: 3rem;
}

/*
.hero .content {
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	text-align: left;
}

.hero .content h1 {
	font-size: 4rem;
	padding: 0.6rem 0 1.5rem;
	 
}*/

.hero .content p {
	font-size: 1.4rem;
	font-weight: 200;
	/*text-transform: uppercase;*/
}

.content .btn {
	margin: 1rem 0.2rem;
}

@media screen and (max-width: 1200px) {
	.hero .content {
		width: 80%;

		padding: 0rem;
	}
}

@media screen and (max-width: 880px) {
	.hero .content {
		width: 100%;

		padding: 1rem;
	}
}

@media screen and (min-height: 720px) {
	.hero {
		height: 100vh;
	}
}
