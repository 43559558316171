.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	position: fixed;
	width: 100%;
	height: 90px;
	z-index: 10;
}

.header-bg {
	background-color: rgba(0, 0, 0, 0.85);
	transition: 0.5s;
}

.nav-menu {
	display: flex;
	justify-content: flex-end;
}

.nav-menu li {
	padding: 0 1rem;
}

.nav-menu li a {
	font-size: 1.2rem;
	font-weight: 500;
}

.hamburger {
	display: none;
}

.slide-right {
	overflow: hidden;
	display: none;
}

.logo:hover + .slide-right {
	display: block;
	animation: 0.5s slide-right;
}

@keyframes slide-right {
	from {
		margin-left: -100%;
		width: 300%;
		opacity: 0;
	}

	to {
		margin-left: 0%;
		width: 100%;
		opacity: 1;
	}
}

@media screen and (max-width: 1020px) {
	.nav-menu {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.9);
		position: absolute;
		top: 0;
		left: -100%;
		z-index: -3;
		transition: 0.3s;
	}

	.nav-menu.active {
		left: 0;
	}

	.nav-menu li {
		padding: 1rem 0;
	}

	.nav-menu li a {
		font-size: 2rem;
	}
	.hamburger {
		display: initial;
	}
}
