@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

.content-heading {
	text-align: center;
}

.article-container {
	width: 100%;
	padding-bottom: 4rem;
	padding-top: 90px;

	position: relative;
	top: 0;
	left: 0;

	background: linear-gradient(
		-45deg,
		#ee765288,
		#e73c7e83,
		#23a5d571,
		#23d5ab8e
	);
	opacity: 1;
}

.article-container p,
.article-container span {
	color: #fff;
	text-decoration: none;
	font-family: "Lora", sans-serif;
}

.content-container {
	max-width: 1000px;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 30px;
	padding: 8rem;
	background-color: rgba(0, 0, 0, 0.8);
}

.content-container::after {
	background-image: radial-gradient(
		rgba(255, 255, 255, 0.2) 5%,
		transparent 100%
	);
	background-position: 0% 0%;
	background-size: 2rem 2rem;
	height: calc(100%);
	padding: 3vmin;
	position: relative;
	width: calc(100%);
	z-index: 3;
}

.content-container h1 {
	font-size: 2.4rem;
}

.article-img {
	width: calc(100% + 2rem);

	margin-left: -1rem;
	padding: 1rem 0;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.image-grid {
	width: calc(100% + 2rem);
	margin-left: -1rem;
}

.article-img3 {
	max-width: 1140px;
	margin: auto;
	display: grid;
	text-align: center;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	margin-bottom: -2.5rem;
}

.article-img2 {
	max-width: 1140px;
	margin: auto;
	display: grid;

	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	margin-bottom: -2.5rem;
	text-align: center;
}

.content-info {
	border: solid rgb(254 254 254);
	border-width: 1px 0 1px 0;
	padding: 1rem 0;
}

span {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 1.1rem;
}

.images {
	justify-content: center;
	align-items: center;
	text-align: center;
}

.quote {
	box-shadow: inset 3px 0 0 0 rgb(254 254 254);
	margin-left: -20px;
	padding-left: 20px;
}

.quote p {
	font-size: 1.1rem;
	font-weight: 600;
	font-family: "Outfit", sans-serif;
}

.content-title {
	color: #fff;
	padding: 1rem 0;
}

.content-details p {
	padding: 1rem 0;
	font-weight: 200;
}

.article-img p {
	font-size: 1rem;
	text-align: center;
	padding: 0.5rem 0;
}

.content-details li {
	color: #fff;

	text-decoration: dotted;
	padding: 1rem 0;
	margin: -0.5rem 1.2rem 0;
	list-style-type: circle;
	list-style-position: outside;
}

.content-details span {
	position: relative;
	left: 2rem;
	font-weight: 200;
}

.article-container > .content-container > .content-details > .light > p {
	font-weight: bold;
	font-style: italic;
}

@media screen and (max-width: 1024px) {
	.content-container {
		max-width: 90%;
		padding: 4rem;
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 700px) {
	.content-container {
		max-width: 90%;
		padding: 2rem;
		grid-template-columns: 1fr;
	}
	.article-img2 {
		grid-template-columns: 2fr;
	}
	.article-img3 {
		grid-template-columns: 2fr;
	}
}
