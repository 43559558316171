.hero-img {
	width: 100%;

	height: 30vh;
	position: relative;
	padding-top: 100px;
}

.hero-img::before {
	content: "";

	background-size: cover;
	background-position: center top;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.heading {
	width: 100%;
	height: 100%;
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem 1rem 3rem;
}

.hero-img h1 {
	font-size: 2.4rem;
}

.hero-img .small {
	font-size: 1rem;
}

.hero-img p {
	font-size: 1.4rem;
	text-align: center;
}

@media screen and (max-width: 640px) {
	.hero-img h1 {
		font-size: 2rem;
	}
	.hero-img p {
		font-size: 1.2rem;
		text-align: center;
	}
	.hero-img {
		height: 45vh;
	}
}
