.heading {
	text-align: center;
	padding-bottom: 1rem;
}

.text-info {
	height: 100%;
	max-width: 100%;
	display: flex;
	display: none;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 1rem 1rem 0 1rem;
}

label {
	color: #fff;
	margin-bottom: 0.5rem;
}

.auth {
	margin: 0;
	width: 100%;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

form {
	display: flex;
	flex-direction: column;
	padding: 2rem 2rem 2rem 2rem;
	margin: auto;
	max-width: 400px;
	border: 1px solid #eee;
	background-color: #141414;
}

.profile {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 2rem 2rem 2rem 2rem;
	margin: auto;
	max-width: 400px;
	border: 1px solid #eee;
	background-color: #141414;
}

.center {
	height: 100vh;
}

form input {
	margin-bottom: 1rem;
	padding: 10px 18px;
	font-size: 1.2rem;
	background-color: rgba(255, 255, 255, 0.1);
	border-color: rgba(255, 255, 255, 0.1);
	color: #f4f4f4;
}

form p {
	font-size: 1rem;
}

.auth__error {
	font-size: 1rem;
	color: rgb(255, 67, 67);
	padding: 1rem;
}
