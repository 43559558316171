@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Outfit", sans-serif;
}

body {
	background: rgb(8, 8, 7);
}

h1,
h2,
h3,
h4 {
	color: #fff;
	text-decoration: none;
}

strong,
p,
a {
	color: #fff;
	text-decoration: none;
	line-height: 160%;
	font-size: 1.1rem;
}

ul {
	list-style-type: none;
}

.content-info span {
	color: rgba(255, 255, 255, 0.8);
	text-decoration: none;
}

.btn {
	padding: 12px 32px;
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 700;
	color: #fff;
	border: 0.5vmin solid rgb(3, 169, 244);
	background-color: rgb(0, 0, 0, 0.1);
	background-image: radial-gradient(
		rgba(255, 255, 255, 0.2) 5%,
		transparent 8%
	);
	background-position: 0% 0%;
	background-size: 1.5rem 1.5rem;

	position: relative;
}

.btn-light {
	background: transparent;
	color: #fff;
}

.btn-long {
	display: flex;
	justify-content: center;
}

.btn:hover {
	background-color: rgba(171, 171, 171, 0.8);
	color: #fff;
	transition: 0.3s;
	background-position: -10% 0%;
}
