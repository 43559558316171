.footer {
	width: 100%;

	padding: 3rem 0;
	background-color: rgba(19, 19, 19, 0.8);
	align-items: center;
}

.footer-container {
	max-width: 1140;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(1fr);
	grid-gap: 0px;
}

.top {
	height: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 0 1rem;
	text-align: center;
}

.location {
	display: flex;
	margin-bottom: 0.8rem;
}
.location p {
	line-height: 30px;
}

.bottom {
	height: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	gap: 2rem;
}

.bottom > .social {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.bottom > .email {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 640px) {
	.footer-container {
		grid-template-columns: 1fr;
	}
}
